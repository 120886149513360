<div class="flex flex-col rounded-lg bg-palette-base-white">
  <span
    *ngIf="showTableSearch || showTableFilter"
    class="h-[68px] bg-palette-base-white border-t-palette-gray-200 border-t-2 flex justify-end items-center gap-3"
    [ngClass]="{
      'rounded-t-xl': tableName === 'campaign',
      'pr-4': showTableFilter,
      'rounded-t-lg': roundedTable
    }">
    <div class="p-input-icon-left w-[360px]" *ngIf="showTableSearch" [ngClass]="{ 'mr-4': !showTableFilter }">
      <i class="sctr-icon-search-lg text-system-gray-500 absolute"></i>
      <input
        type="text"
        id="table-search"
        pInputText
        [formControl]="search"
        [placeholder]="'COMMON.SEARCH_BY' | translate : { name: searchPlaceholder | translate | lowercase }"
        class="table-search" />
    </div>
    <p-multiSelect
      *ngIf="showTableFilter"
      #statusMultiSelect
      optionLabel="name"
      class="filter-select"
      [(ngModel)]="selectedStatus"
      [options]="statusOptions"
      [filter]="false"
      [placeholder]="'COMMON.STATUS' | translate">
      <ng-template let-value pTemplate="selectedItems">
        <div class="flex gap-2 justify-center">
          <span class="font-semibold"> {{ 'COMMON.STATUS' | translate }}: </span>
          <ng-container *ngIf="value?.length < 3; else selectedItems">
            {{ selectedStatusList() }}
          </ng-container>
          <ng-template #selectedItems>
            {{ 'COMMON.ITEMS_SELECTED' | translate : { number: value?.length } }}
          </ng-template>
        </div>
      </ng-template>
      <ng-template let-option pTemplate="item">
        {{ 'COMMON.' + upperCaseFormat(option.name) | translate }}
      </ng-template>

      <ng-template let-option pTemplate="footer">
        <p-divider></p-divider>
        <div class="flex justify-end box-border pb-5 pr-5">
          <p-button class="active-btn" (click)="onFilterStatus()">{{ 'ACTION_BUTTONS.APPLY' | translate }}</p-button>
        </div>
      </ng-template>
    </p-multiSelect>

    <div
      *ngIf="showViewOption && !showOnlyGridLayout"
      class="view-option rounded-lg border border-system-gray-300 mr-4 flex">
      <button
        class="view-option-item rounded-l-lg"
        (click)="setViewOption(viewOption.list)"
        [ngClass]="{ active: selectedViewOption === viewOption.list }">
        <i class="sctr-icon-list self-center text-base"></i>
        <span>{{ 'SMART_TABLE.VIEW_OPTION.LIST' | translate }}</span>
      </button>
      <button
        class="view-option-item rounded-r-lg"
        (click)="setViewOption(viewOption.grid)"
        [ngClass]="{ active: selectedViewOption === viewOption.grid }">
        <i class="sctr-icon-grid-01 self-center text-base"></i>
        <span>{{ 'SMART_TABLE.VIEW_OPTION.GRID' | translate }}</span>
      </button>
    </div>
  </span>
  <div *ngIf="isShowConfigHeaderFooter" class="flex flex-col p-4 gap-4 border-y">
    <div class="flex justify-between">
      <span class="font-semibold">{{ 'Config header and footer' }}</span>
      <button
        [disabled]="
          !templateSelectedArray.length || !selectedHeaderTemplate || !selectedFooterTemplate || isLoadingApplyTemplate
        "
        class="text-palette-blue-600 bg-palette-base-white border border-palette-blue-600 w-[91px] h-[36px] rounded-lg font-semibold disabled:opacity-70"
        (click)="handleClickApplyTemplate()">
        <div class="flex items-center text-center justify-center gap-1">
          <i *ngIf="isLoadingApplyTemplate" class="pi pi-spin sctr-icon-loading-02"></i>
          {{ 'ACTION_BUTTONS.APPLY' | translate }}
        </div>
      </button>
    </div>
    <div class="flex justify-between w-full gap-4">
      <div class="w-1/2">
        <span class="font-medium block">{{ 'TEMPLATES.HEADER' | translate }}</span>
        <p-dropdown
          styleClass="h-[42px] w-full"
          [options]="headerTemplates"
          [(ngModel)]="selectedHeaderTemplate"
          (ngModelChange)="onSelectHeaderTemplate.emit($event)"
          optionLabel="template_name"
          placeholder="Select header"
          [virtualScroll]="true"
          [virtualScrollItemSize]="paging.pageSize * 4"
          (onLazyLoad)="loadMoreHeader($event)"
          [lazy]="true" />
        <div *ngIf="selectedHeaderTemplate">
          <div
            appScaleContent
            class="container h-[120px] relative overflow-hidden flex justify-center items-center pointer-events-none bg-palette-gray-50">
            <div class="content min-w-[1000px] overflow-hidden">
              <em-thumbnail-html [html]="selectedHeaderTemplate.content"></em-thumbnail-html>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/2">
        <span class="font-medium block">{{ 'TEMPLATES.FOOTER' | translate }}</span>
        <p-dropdown
          styleClass="h-[42px] w-full"
          [options]="footerTemplates"
          [(ngModel)]="selectedFooterTemplate"
          (ngModelChange)="onSelectFooterTemplate.emit($event)"
          optionLabel="template_name"
          placeholder="Select footer"
          [virtualScroll]="true"
          [virtualScrollItemSize]="paging.pageSize * 4"
          (onLazyLoad)="loadMoreFooter($event)"
          [lazy]="true" />
        <div *ngIf="selectedFooterTemplate">
          <div
            appScaleContent
            class="container h-[120px] relative overflow-hidden flex justify-center items-center pointer-events-none bg-palette-gray-50">
            <div class="content min-w-[1000px] overflow-hidden">
              <em-thumbnail-html [html]="selectedFooterTemplate.content"></em-thumbnail-html>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-table
    *ngIf="selectedViewOption === viewOption.list && !showOnlyGridLayout"
    class="smart-table"
    [paginator]="false"
    [value]="tableData"
    [rows]="10"
    [paginator]="useDefaultPaginator"
    [(first)]="paging.pageNum"
    [alwaysShowPaginator]="false"
    [rowsPerPageOptions]="[5, 10, 20]"
    (selectionChange)="onSelectionChange($event)">
    <ng-template pTemplate="header">
      <tr class="bg-system-gray-100 h-10">
        <th *ngIf="showTableCheckbox && !showHeaderCheckbox" style="width: 1rem"></th>
        <th *ngIf="showHeaderCheckbox" style="width: 1rem; padding-left: 18px">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>

        <th
          *ngFor="let header of tableHeaders"
          alignFrozen="right"
          pFrozenColumn
          [ngClass]="{
            'number-value': isNumberValue(header),
            '!text-center': header === 'ACTIONS',
            'w-[50%]': header === 'EMAIL' && tableName === 'campaignDetail',
            'center-label': ['OPENED_EMAIL', 'CLICKED_EMAIL'].includes(header) && tableName === 'campaignDetail'
          }">
          <span class="text-palette-gray-900 font-semibold text-sm"
            >{{ 'SMART_TABLE.HEADER.' + header | translate }}
          </span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
      <tr>
        <td *ngIf="showTableCheckbox" style="padding-left: 18px">
          <i
            *ngIf="checkBoxType === checkBoxTypeConstant.star; else normalCheckbox"
            class="sctr-icon-solid-star-01 text-lg cursor-pointer"
            [ngClass]="item?.is_favorite ? 'text-palette-yellow-400' : 'text-palette-gray-300'"
            (click)="selectItemEvent.emit(item)"></i>

          <ng-template #normalCheckbox>
            <p-tableCheckbox [value]="item"></p-tableCheckbox>
          </ng-template>
        </td>
        <td
          *ngFor="let key of tableKeys; let i = index"
          class="primary-text"
          [ngClass]="{
            'min-w-[250px] max-w-[300px]':
              (key === 'name' && tableName === itemNames.audience) ||
              (key === 'name' && tableName === itemNames.campaign),
            'min-w-[250px] max-w-[800px]': key === 'template_name' && tableName === itemNames.templates,
            'number-value': isNumberValue(key, false),
            'center-label': ['is_opened', 'is_clicked'].includes(key) && tableName === 'campaignDetail'
          }"
          pFrozenColumn
          alignFrozen="right">
          <div
            *ngIf="
              (key === 'type' && tableName === itemNames.audience) ||
              (key === 'status' && tableName === itemNames.automation)
            "
            class="flex rounded-full px-2 pt-[2px] border border-solid w-fit h-6 whitespace-nowrap"
            [ngClass]="updateStatusColor(item[key])">
            <span class="text-xs font-medium">{{ 'COMMON.' + upperCaseFormat(item[key]) | translate }} </span>
          </div>
          <div
            *ngIf="key === 'actions'"
            class="flex items-center justify-center"
            [ngClass]="{
              'gap-3': ![itemNames.campaign, itemNames.automation, itemNames.audience].includes(tableName),
              'pl-12': item?.is_draft
            }">
            <ng-container [ngSwitch]="tableName">
              <ng-container *ngSwitchCase="itemNames.audience">
                <span
                  class="text-sm text-palette-blue-600 font-semibold cursor-pointer"
                  (click)="refreshUserCountEvent.emit(item)"
                  >{{ 'COMMON.REFRESH' | translate }}</span
                >
                <ng-container [ngTemplateOutlet]="menuButton" [ngTemplateOutletContext]="{ item: item }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="itemNames.userList">
                <i
                  class="sctr-icon-trash-03 text-palette-red-600 text-base cursor-pointer"
                  (click)="onDeleteItem(item)"></i>
              </ng-container>
              <ng-container *ngSwitchCase="itemNames.campaign">
                <p-inputSwitch
                  [id]="'campaign-input-switch-' + item?.id"
                  *ngIf="!item?.is_draft"
                  [ngModel]="item.enabled"
                  (onChange)="onChangeInputSwitch(item, $event)"></p-inputSwitch>
                <ng-container [ngTemplateOutlet]="menuButton" [ngTemplateOutletContext]="{ item: item }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="itemNames.templates">
                <ng-container [ngTemplateOutlet]="menuButton" [ngTemplateOutletContext]="{ item: item }"></ng-container>
              </ng-container>
              <ng-container *ngSwitchCase="itemNames.automation">
                <p-inputSwitch
                  *ngIf="!item?.is_draft"
                  [ngModel]="item.enabled"
                  (onChange)="onChangeInputSwitch(item, $event)"></p-inputSwitch>
                <ng-container [ngTemplateOutlet]="menuButton" [ngTemplateOutletContext]="{ item: item }"></ng-container>
              </ng-container>
            </ng-container>
          </div>
          <div *ngIf="key === 'full_name'" class="flex items-center gap-1">
            <img [src]="item['avatar_thumbnail_url'] | imageUrlFormat" alt="User avatar" class="rounded-full h-6 w-6" />
            <span class="break-all font-normal text-palette-gray-900">{{ item['full_name'] }}</span>
          </div>
          <span *ngIf="key === 'template_name'" class="text-palette-blue-600 cursor-pointer">
            {{ item['template_name'] }}
          </span>
          <span *ngIf="['created_at', 'updated_at', 'add_date'].includes(key)">{{
            item[key] | dateFormat : true : true : 'dd/MM/YYY HH:mm'
          }}</span>
          <span *ngIf="key === 'schedule'">{{ item[key]?.send_time | dateFormat : true : true }}</span>
          <div
            *ngIf="key === 'status' && tableName === itemNames.campaign"
            class="flex rounded-full px-2 pt-[2px] border border-solid w-fit h-6 whitespace-nowrap"
            [ngClass]="updateStatusColor(getCampaignStatus(item))">
            <span class="text-xs font-medium">{{ 'COMMON.' + getCampaignStatus(item) | translate }} </span>
          </div>
          <span *ngIf="['email_clicked', 'email_opened'].includes(key)"> {{ item[key] | roundDecimal }}% </span>
          <span
            *ngIf="['name', 'automation_name'].includes(key)"
            class="text-palette-blue-600 cursor-pointer"
            (click)="viewItemEvent.emit(item)"
            >{{ item[key] }}</span
          >
          <span *ngIf="['is_opened', 'is_clicked'].includes(key) && tableName === 'campaignDetail'">
            <i *ngIf="item[key]" class="sctr-icon-check-circle text-palette-green-600 text-lg"> </i>
            <span *ngIf="!item[key]" class="text-sm text-palette-gray-300">—</span>
          </span>
          <span
            *ngIf="key === 'email' && tableName === 'campaignDetail'"
            class="text-sm text-palette-gray-700 font-medium">
            {{ item?.user_info?.email }}</span
          >
          <span
            *ngIf="
              ![
                'type',
                'actions',
                'full_name',
                'name',
                'automation_name',
                'status',
                'template_name',
                'phone',
                'created_at',
                'updated_at',
                'schedule',
                'email_opened',
                'email_clicked',
                'is_clicked',
                'is_opened',
                'add_date'
              ].includes(key) ||
              (key === 'email' && tableName === 'campaignDetail')
            "
            class="break-allfont-normal text-palette-gray-900"
            >{{ item[key] }}
          </span>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div class="font-semibold p-4" *ngIf="isShowConfigHeaderFooter">
    {{ 'Email list' }}
  </div>
  <div *ngIf="showOnlyGridLayout" class="grid grid-cols-5 px-4 gap-4 pb-4">
    <ng-template [ngIf]="isLoading" [ngIfElse]="notLoading">
      <p-card
        *ngFor="let number of [].constructor(10)"
        styleClass="h-full bg-palette-base-white border border-solid border-[#eaecf0] shadow-none">
        <ng-template pTemplate="header">
          <div class="max-h-[200px] w-full">
            <p-skeleton height="200px" />
          </div>
        </ng-template>
        <div class="p-4">
          <p-skeleton height="14px" />
        </div>
        <div class="h-5 w-max ml-4 mb-4 rounded-xl">
          <p-skeleton width="3rem" height="14px" />
        </div>
      </p-card>
    </ng-template>
    <ng-template #notLoading>
      <ng-container *ngFor="let item of tableData; let templateIndex = index">
        <p-card
          (click)="handleSelectedCheckBox(item.id)"
          styleClass="h-full bg-palette-base-white border border-solid border-[#eaecf0] shadow-none cursor-pointer"
          [ngClass]="{
            'border-palette-blue-600 border rounded-lg': item?.id === selectedItem?.id && isSelectTemplateTable,
            'cursor-pointer': 'isSelectTemplateTable'
          }"
          (click)="onSelectTemplate(item)">
          <ng-template pTemplate="header">
            <div class="max-h-[200px] w-full rounded-t-lg flex justify-center bg-[#f2f4f7] relative">
              <p-checkbox
                *ngIf="configTemplate && isShowConfigHeaderFooter"
                styleClass="absolute top-3 left-3 z-50"
                [ngModel]="templateSelectedArray"
                name="group"
                [value]="item.id" />

              <div
                appScaleContent
                class="container h-[200px] relative overflow-hidden flex justify-center items-center pointer-events-none">
                <div
                  class="content overflow-hidden"
                  [ngClass]="{ 'min-w-[400px]': isLargeThumbnail, 'min-w-[1000px]': !isLargeThumbnail }">
                  <em-thumbnail-html [html]="item.html || item.content"></em-thumbnail-html>
                </div>
              </div>

              <i
                *ngIf="checkBoxType === checkBoxTypeConstant.star && showTableCheckbox"
                class="sctr-icon-solid-star-01 text-2xl absolute top-3 right-3"
                [ngClass]="item?.is_favorite ? 'text-palette-yellow-400' : 'text-palette-gray-300'"
                (click)="$event.stopPropagation(); selectItemEvent.emit(item)"></i>
            </div>
          </ng-template>
          <div class="px-4 pt-4 pb-2">
            <span class="text-sm text-palette-gray-900">{{ item.template_name | truncate : 30 }}</span>
          </div>
          <div class="flex justify-between items-center mb-2">
            <div
              class="h-5 w-max ml-4 rounded-xl px-2 font-medium text-xs"
              [ngClass]="updateModuleColor(item.module_type)">
              {{ item.module_type | capitalize }}
            </div>
            <ng-container *ngIf="configTemplate">
              <ng-container [ngTemplateOutlet]="menuButton" [ngTemplateOutletContext]="{ item: item }"></ng-container>
            </ng-container>
          </div>
        </p-card>
      </ng-container>
    </ng-template>
  </div>

  <div *ngIf="totalElements > selectedRowsPerPage[0] && !useDefaultPaginator" class="px-5 py-3">
    <em-paginator
      [paging]="paging"
      [totalElements]="totalElements"
      [showPageSizeSelection]="showPageSizeSelection"
      (pagingChange)="onChangePaging($event)"
      (pageSizeChange)="pageSizeChangeEvent.emit($event)"
      (pageNumChange)="pageNumChangeEvent.emit($event)"></em-paginator>
  </div>
</div>

<ng-template #menuButton let-item="item">
  <p-menu
    #menu
    [model]="tableActionsMenu"
    [popup]="true"
    appendTo="body"
    class="max-w-[200px]"
    (onHide)="isOpenActionsMenu = false">
  </p-menu>
  <button
    class="w-10 h-10 rounded-lg focus:bg-system-gray-100 hover:bg-system-gray-100"
    (click)="$event.stopPropagation(); menu.toggle($event)"
    (click)="onClickMenuButton(item)">
    <i class="sctr-icon-dots-vertical text-base"></i>
  </button>
</ng-template>

<p-dialog
  header="{{ 'TEMPLATES.PREVIEW_EMAIL' | translate }}"
  [(visible)]="previewHtmlCode"
  [modal]="true"
  (onHide)="previewHtmlCodeChange.emit()"
  styleClass="min-w-[80rem] max-w-[80rem]">
  <div class="content">
    <em-thumbnail-html [html]="previewHtmlCode"></em-thumbnail-html>
  </div>
</p-dialog>
