import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'em-preview-email',
  templateUrl: './preview-email.component.html',
  styleUrls: ['./preview-email.component.scss']
})
export class PreviewEmailComponent {
  @Input() html = '';
  @Input() showPreviewEmailDialog = false;
  @Output() showPreviewEmailDialogChange = new EventEmitter();
}
