export const TABLE_HEADER_LIST = new Map<string, string>([
  ['GROUP_TYPE', 'type'],
  ['GROUP_NAME', 'name'],
  ['CREATED_DATE', 'created_at'],
  ['UPDATED_DATE', 'updated_at'],
  ['NEW_USERS_IN_30_DAYS', 'number_of_new_receiver'],
  ['USER_COUNTS', 'number_of_receiver'],
  ['LOCATION', 'region'],
  ['USER_NAME', 'username'],
  ['PHONE_NUMBER', 'phone'],
  ['OPEN_RATE', 'email_opened'],
  ['CLICK_RATE', 'email_clicked'],
  ['CAMPAIGN_NAME', 'name'],
  ['OPENED_EMAIL', 'is_opened'],
  ['CLICKED_EMAIL', 'is_clicked'],
  ['DATE_ADDED', 'add_date']
]);
