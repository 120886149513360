import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'singleFieldFilter'
})
export class SingleFieldFilterPipe implements PipeTransform {
  transform(value: any[], searchText: any, keySearch: string): any[] {
    if (!value || !searchText) {
      return value;
    }
    return value.filter(item => item[keySearch].includes(searchText));
  }
}
