export const SIDEBAR_ITEMS = [
  // {
  //   url: '/dashboard',
  //   icon: 'sctr-icon-bar-chart-square-01',
  //   label: 'SIDEBAR_ITEMS.DASHBOARD',
  //   showTooltip: false
  // },
  {
    url: '/automation',
    icon: 'sctr-icon-refresh-cw-02',
    label: 'SIDEBAR_ITEMS.AUTOMATION',
    showTooltip: false,
    name: 'AUTOMATION'
  },
  {
    url: '/campaign',
    icon: 'sctr-icon-heart-hand',
    label: 'SIDEBAR_ITEMS.CAMPAIGN',
    showTooltip: false,
    name: 'CAMPAIGN',
    isExpanded: false
  },
  {
    url: '/audience',
    icon: 'sctr-icon-solid-users-01',
    label: 'SIDEBAR_ITEMS.AUDIENCE',
    showTooltip: false,
    name: 'AUDIENCE',
    isExpanded: false
  },
  {
    icon: 'sctr-icon-mail-02',
    label: 'SIDEBAR_ITEMS.TEMPLATES',
    showTooltip: false,
    name: 'TEMPLATES',
    isExpanded: true,
    children: [
      {
        url: '/templates/system-email',
        icon: 'sctr-icon-solid-circle',
        label: 'SIDEBAR_ITEMS.SYSTEM_EMAIL',
        showTooltip: false,
        name: 'SYSTEM_EMAIL'
      },
      {
        url: '/templates/email-marketing',
        icon: 'sctr-icon-solid-circle',
        label: 'SIDEBAR_ITEMS.EMAIL_MARKETING',
        showTooltip: false,
        name: 'EMAIL_MARKETING'
      },
      {
        url: '/templates/email-header-footer',
        icon: 'sctr-icon-solid-circle',
        label: 'SIDEBAR_ITEMS.EMAIL_HEADER_FOOTER',
        showTooltip: false,
        name: 'EMAIL_HEADER_FOOTER'
      }
    ]
  }
  // {
  //   url: '/landing-page',
  //   icon: 'sctr-icon-file-shield-03',
  //   label: 'SIDEBAR_ITEMS.LANDING_PAGE',
  //   showTooltip: false
  // }
];

export const CHARACTER_TRANSLATION_LIMIT = 5000;

export const ITEM_NAMES = {
  dashboard: 'dashboard',
  automation: 'automation',
  templates: 'templates',
  landingPage: 'landingPage',
  audience: 'audience',
  userList: 'userList',
  campaign: 'campaign'
};

export const AUDIENCE_GROUP_OPTIONS = {
  all: 'ALL',
  manual: 'MANUAL',
  automatic: 'AUTOMATIC'
};

export const MARKETING_STATUS = {
  manual: 'MANUAL',
  automatic: 'AUTOMATIC',
  inactive: 'INACTIVE',
  draft: 'DRAFT',
  active: 'ACTIVE',
  all: 'ALL'
};

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const CONDITION = {
  and: 'AND',
  or: 'OR'
};

export const RULE_TYPES = {
  dynamic: 'DYNAMIC',
  static: 'STATIC'
};
export const CHECKBOX_TYPE = {
  normal: 'normal',
  star: 'star'
};

export const VIEW_OPTION = {
  list: 'LIST',
  grid: 'GRID'
};

export const DefaultPaginator = {
  numberRowPerPage: 10,
  rowsPerPageOptions: [10, 30, 50]
};

export const PAGE_SIZE_OPTIONS = [
  { label: '10', size: 10 },
  { label: '30', size: 30 },
  { label: '50', size: 50 }
];

export const PAGE_NUM_DEFAULT = 0;
export const PAGE_SIZE_DEFAULT = 10;
export const TOTAL_ELEMENT_DEFAULT = 0;

export const TRIGGER = {
  createAccount: 'CREATE_ACCOUNT',
  audienceGroup: 'AUDIENCE_GROUP',
  anniversaryDate: 'ANNIVERSARY_DATE',
  macthDate: 'MATCH_DATE'
};

export const DATE_PROPERTY = {
  birdthday: 'BIRTHDAY'
};

export const FLOW_START = {
  dateProperty: 'DATE_PROPERTY'
};

export const FLOW_START_DROPDOWN = [FLOW_START.dateProperty];

export const TIMEZONE = {
  local: 'RECIPIENT_TIMEZONE'
};

export const TIMEZONE_DROPDOWN = [TIMEZONE.local];

export const CONDITION_DROPDOWN = [CONDITION.and, CONDITION.or];

export const TIME = {
  minutes: 'MINUTE',
  hours: 'HOUR',
  days: 'DAY',
  weeks: 'WEEK',
  months: 'MONTH',
  years: 'YEAR'
};

export const TIME_DROPDOWN = [TIME.minutes, TIME.hours, TIME.days, TIME.weeks, TIME.months, TIME.years];

export const WORKFLOW_TYPE = {
  trigger: 'TRIGGER',
  email: 'EMAIL',
  delay: 'DELAY',
  condition: 'CONDITION'
};

export const MARKETING_TOPICS = [
  { name: 'SOCIAL', value: 'social' },
  { name: 'SHOP', value: 'shop' },
  { name: 'FLIGHT', value: 'flight' },
  { name: 'BOOKING', value: 'booking' },
  { name: 'CAR', value: 'car' },
  { name: 'TOUR', value: 'tour' },
  { name: 'TICKET', value: 'ticket' },
  { name: 'CROSS_SALE', value: 'cross-sale' }
];

export const DYNAMIC_DATA_OPTIONS = {
  hobbies: 'FE_SOCIAL_HOBBIES',
  gender: 'FE_SOCIAL_GENDER'
};

export const INTEREST_LEVEL = {
  interests: 'INTERESTS',
  categories: 'CATEGORIES',
  contents: 'CONTENTS'
};

export const AGE = {
  age_min: 13,
  age_max: 66,
  max_age_limit: 65
};

export const TRIGGER_CONDITION = {
  someoneDone: 'SOMEONE_DONE',
  someoneProximity: 'SOMEONE_PROXIMITY',
  someoneInVietnam: 'SOMEONE_IN_VIETNAM',
  someoneInUSA: 'SOMEONE_IN_USA',
  someoneAbout: 'SOMEONE_ABOUT'
};

export const TRIGGER_CONDITION_DROPDOWN = [
  TRIGGER_CONDITION.someoneDone,
  TRIGGER_CONDITION.someoneProximity,
  TRIGGER_CONDITION.someoneInVietnam,
  TRIGGER_CONDITION.someoneInUSA,
  TRIGGER_CONDITION.someoneAbout
];

export const USER_ACTION = {
  clickedEmail: 'CLICKED_EMAIL',
  openedEmail: 'OPENED_EMAIL'
};

export const USER_ACTION_DROPDOWN = [USER_ACTION.clickedEmail, USER_ACTION.openedEmail];

export const FREQUENCY = {
  atLeastOnce: 'AT_LEAST_ONCE'
};

export const FREQUENCY_DROPDOWN = [FREQUENCY.atLeastOnce];

export const TIME_FRAME = {
  inTheLast: 'IN_THE_LAST'
};

export const TIME_FRAME_DROPDOWN = [TIME_FRAME.inTheLast];

export const CONDITION_BRANCH = {
  yesBranch: 'yes_branch',
  noBranch: 'no_branch'
};

export const ZOOM_CHANGE_VALUE = 0.25;

export const EMAIL_TYPE = {
  system: 'SYSTEM',
  marketing: 'MARKETING',
  headerFotter: 'HEADER_FOOTER'
};

export const HOBBIES = [
  { 'name': 'BAKING' },
  { 'name': 'BLOGGING' },
  { 'name': 'BOARDGAMES' },
  { 'name': 'COLLECTING' },
  { 'name': 'COOKING' },
  { 'name': 'CRAFTING' },
  { 'name': 'CYCLING' },
  { 'name': 'DANCING' },
  { 'name': 'DRAWING' },
  { 'name': 'EXERCISING' },
  { 'name': 'FISHING' },
  { 'name': 'GAMING' },
  { 'name': 'GARDENING' },
  { 'name': 'HIKING' },
  { 'name': 'INSTRUMENTS' },
  { 'name': 'KNITTING' },
  { 'name': 'LANGUAGES' },
  { 'name': 'MUSIC' },
  { 'name': 'PAINTING' },
  { 'name': 'PHOTOGRAPHY' },
  { 'name': 'READING' },
  { 'name': 'SEWING' },
  { 'name': 'SHOPPING' },
  { 'name': 'SINGING' },
  { 'name': 'SKATEBOARDING' },
  { 'name': 'SKATING' },
  { 'name': 'SKIING' },
  { 'name': 'SKYDIVING' },
  { 'name': 'SURFING' },
  { 'name': 'TRAVELING' },
  { 'name': 'WALKING' },
  { 'name': 'WRITING' },
  { 'name': 'OTHERS' }
];

export const KNOWN_RULES = [
  'AGE',
  'LOCATION',
  'GENDER',
  'BIRTHDAY',
  'INTEREST',
  'NOT_ACCESS_SOCTRIP',
  'REGISTERED_SOCTRIP'
];

export const TIMEZONE_OPTIONS = [
  { name: 'Recipient timezone', value: 'RECIPIENT_TIMEZONE' },
  { name: 'Hawaii Standard Time, Tahiti Time (GMT-10:00)', value: '-10:00' },
  { name: 'Pacific Time (GMT-08:00)', value: '-08:00' },
  { name: 'Mountain Time (GMT-07:00)', value: '-07:00' },
  { name: 'Central Time (GMT-06:00)', value: '-06:00' },
  { name: 'Eastern Time, Atlantic Time (GMT-05:00)', value: '-05:00' },
  { name: 'Argentina Time, Brazil Time (GMT-03:00)', value: '-03:00' },
  { name: 'South Georgia Time (GMT-02:00)', value: '-02:00' },
  { name: 'Azores Time (GMT-01:00)', value: '-01:00' },
  { name: 'Greenwich Mean Time (GMT+00:00)', value: '+00:00' },
  { name: 'Central European Time (GMT+01:00)', value: '+01:00' },
  { name: 'Eastern European Time (GMT+02:00)', value: '+02:00' },
  { name: 'Moscow Standard Time (GMT+03:00)', value: '+03:00' },
  { name: 'Gulf Standard Time (GMT+04:00)', value: '+04:00' },
  { name: 'Pakistan Standard Time (GMT+05:00)', value: '+05:00' },
  { name: 'India Standard Time (GMT+05:30)', value: '+05:30' },
  { name: 'Bangladesh Standard Time (GMT+06:00)', value: '+06:00' },
  { name: 'Indochina Time (GMT+07:00)', value: '+07:00' },
  { name: 'China Standard Time (GMT+08:00)', value: '+08:00' },
  { name: 'Japan Standard Time (GMT+09:00)', value: '+09:00' },
  { name: 'Australian Eastern Time (GMT+10:00)', value: '+10:00' },
  { name: 'New Zealand Standard Time (GMT+12:00)', value: '+12:00' }
];

export const SYSTEM_TEMPLATE_MODULE_LIST = [
  { label: 'All', value: 'ALL' },
  { label: 'Social', value: 'SOCIAL' },
  { label: 'Shop', value: 'SHOP' },
  { label: 'Flight', value: 'FLIGHT' },
  { label: 'Booking', value: 'BOOKING' },
  { label: 'Car', value: 'CAR' },
  { label: 'Tour', value: 'TOUR' },
  { label: 'Ticket', value: 'TICKET' }
];

export const EM_TEMPLATE_MODULE_LIST = [...SYSTEM_TEMPLATE_MODULE_LIST, { label: 'Cross-sale', value: 'CROSS_SALE' }];

export const TEMPLATE_TYPES = {
  SYSTEM: 'SYSTEM',
  MARKETING: 'MARKETING',
  HEADER: 'HEADER',
  FOOTER: 'FOOTER'
};

export const UNIT = {
  option: 'OPTION',
  number: 'NUMBER',
  boolean: 'BOOLEAN',
  range: 'RANGE',
  multi_option: 'MUTIL_OPTION'
};
