export const COLOR_MODULE_MAPPING = new Map<string, string>([
  ['SOCIAL', 'bg-palette-blue-50 text-palette-blue-600'],
  ['SHOP', 'bg-palette-orange-50 text-palette-orange-600'],
  ['FOOD', 'bg-palette-green-50 text-palette-green-600'],
  ['BOOKING', 'bg-palette-fuchsia-50 text-palette-fuchsia-600'],
  ['TOUR', 'bg-palette-purple-50 text-palette-purple-600'],
  ['CAR', 'bg-palette-yellow-50 text-palette-yellow-600'],
  ['FLIGHT', 'bg-palette-cyan-50 text-palette-cyan-600'],
  ['TICKET', 'bg-palette-gray-50 text-palette-gray-600'],
  ['CROSS_SALE', 'bg-palette-amber-50 text-palette-amber-600']
]);
