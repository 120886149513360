import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiFieldsFilter'
})
export class MultiFieldsFilterPipe implements PipeTransform {
  transform(value: any[], searchText: string | null, keySearches: string[]): any[] {
    if (!value || !searchText || !keySearches) {
      return value;
    }
    return value.filter(item => keySearches.some(key => item[key]?.toLowerCase().includes(searchText.toLowerCase())));
  }
}
