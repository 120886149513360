import { HttpClient } from '@angular/common/http';
import { CommonService } from '@app/core/service/common.service';
import { ApiClientConfig } from './api-client.config';
import { AudienceApi } from './audience/api.audience';
import { AutomationApi } from './automation/api.automation';
import { CampaignApi } from './campaign/api.campaign';
import { TemplatesApi } from './templates/api.templates';
import { UserApi } from './user/api.user';

export class ApiClient {
  readonly audience: AudienceApi;
  readonly templates: TemplatesApi;
  readonly campaign: CampaignApi;
  readonly automation: AutomationApi;
  readonly user: UserApi;

  constructor(private http: HttpClient, config: ApiClientConfig, commonService: CommonService) {
    this.audience = new AudienceApi(this.http, config, commonService);
    this.templates = new TemplatesApi(this.http, config, commonService);
    this.campaign = new CampaignApi(this.http, config, commonService);
    this.automation = new AutomationApi(this.http, config, commonService);
    this.user = new UserApi(this.http, config);
  }
}
