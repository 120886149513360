<div
  class="flex flex-col md:flex-row gap-2 items-center"
  [ngClass]="showPageSizeSelection ? 'justify-between' : 'justify-end'">
  <div *ngIf="showPageSizeSelection" class="flex gap-2 items-center justify-center md:justify-start">
    <span class="text-sm text-palette-gray-700">{{ 'SMART_TABLE.ITEMS_PER_PAGE' | translate }}</span>
    <ng-container>
      <p-dropdown
        optionLabel="label"
        optionValue="size"
        appendTo="body"
        styleClass="min-w-[95px] h-9 rounded-lg border border-palette-gray-300 text-center flex items-center justify-center text-sm"
        panelStyleClass="min-w-[95px] px-2 py-0 rounded-md text-sm align-middle"
        [options]="pageSizeOptions"
        [(ngModel)]="selectedPageSize"
        (onChange)="onPageSizeChange($event.value)"></p-dropdown>
    </ng-container>
  </div>
  <div class="md:min-w-fit" *ngIf="totalElements > pageSizeOptions[0].size">
    <p-paginator
      *ngIf="selectedPageSize === paging.pageSize"
      styleClass="h-9 p-0"
      [showFirstLastIcon]="totalElements / paging.pageSize > 5"
      [alwaysShow]="false"
      [first]="paging.pageSize * paging.pageNum"
      [rows]="paging.pageSize"
      [totalRecords]="totalElements"
      (onPageChange)="onPageNumChange($event)"></p-paginator>
  </div>
</div>
