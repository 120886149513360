import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'em-thumbnail-html',
  templateUrl: './thumbnail-html.component.html',
  styleUrls: ['./thumbnail-html.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ThumbnailHtmlComponent {
  @Input() html: string;
}
