import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundDecimal'
})
export class RoundDecimalPipe implements PipeTransform {
  transform(value: number, digits: number = 2): string {
    if (Number.isInteger(value)) {
      return value.toString();
    }
    return value.toFixed(digits);
  }
}
