import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { PAGE_NUM_DEFAULT, PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTIONS, TOTAL_ELEMENT_DEFAULT } from '@app/shared/constant';

export interface Pagination {
  pageNum: number;
  pageSize: number;
}

@Component({
  selector: 'em-paginator',
  templateUrl: './paginator.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent {
  @Input() paging: Pagination = { pageNum: PAGE_NUM_DEFAULT, pageSize: PAGE_SIZE_DEFAULT };
  @Input() totalElements = TOTAL_ELEMENT_DEFAULT;
  @Input() showPageSizeSelection = true;
  @Output() pagingChange = new EventEmitter();
  @Output() pageSizeChange = new EventEmitter();
  @Output() pageNumChange = new EventEmitter();

  selectedPageSize = PAGE_SIZE_OPTIONS[0].size;
  pageSizeOptions = PAGE_SIZE_OPTIONS;

  onPageSizeChange(size: number): void {
    // TODO: Removed after update the new change for other pages.
    this.pagingChange.emit({
      ...this.paging,
      pageSize: size
    });
    this.pageSizeChange.emit(size);
  }

  onPageNumChange(event: any): void {
    // TODO: Removed after update the new change for other pages.
    this.pagingChange.emit({
      ...this.paging,
      pageNum: event.page
    });
    this.pageNumChange.emit(event.page);
  }
}
