import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiClient } from '@app/lib/api/api-clients';
import {
  AddTemplateBody,
  AddTemplateResponse,
  ApplyHeaderFooterBody,
  GetSystemTemplateParams,
  GetSystemTemplateResponse,
  GetTemplateParams,
  GetTemplateResponse,
  GetTemplateResponsePaging,
  UploadFileResponse
} from '@app/lib/api/templates/api.templates.model';
import { environment } from '@env/environment';
import * as DOMPurify from 'isomorphic-dompurify';
import { Observable } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class TemplatesService {
  private api: ApiClient;
  constructor(private sanitizer: DomSanitizer, private httpClient: HttpClient, private commonService: CommonService) {
    this.api = new ApiClient(
      this.httpClient,
      {
        responseTimeout: environment.API_TIMEOUT
      },
      this.commonService
    );
  }

  getPreviewHtmlCode(headerCode: string, contentCode: string, footerCode: string): string {
    const previewHtmlCode = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Email Template</title>
    </head>
    <body>
      <div class="w-[1000px] mx-auto">
       <header>${headerCode}</header>
       <main class="bg-palette-base-white">${contentCode}</main>
       <footer>${footerCode}</footer>
      </div>
    </body>
    </html>`;

    return previewHtmlCode;
  }

  getCleanHtml(html: string): string {
    const cleanHtml = DOMPurify.sanitize(html);
    return this.sanitizer.bypassSecurityTrustHtml(cleanHtml) as string;
  }

  uploadFile(file: File, description: string): Observable<UploadFileResponse> {
    return this.api.templates.uploadFile(file, description);
  }

  getHtmlFile(fileId: string): Observable<string> {
    return this.api.templates.getFile(fileId);
  }

  addTemplate(body: AddTemplateBody, moduleType: string): Observable<AddTemplateResponse> {
    return this.api.templates.addTemplate(body, moduleType);
  }

  getTemplates(params: GetTemplateParams): Observable<GetTemplateResponsePaging> {
    return this.api.templates.getTemplates(params);
  }

  getTemplateById(templateId: string): Observable<GetTemplateResponse> {
    return this.api.templates.getTemplateById(templateId);
  }

  getAvailableParameters(module: string, templateId: string): Observable<any> {
    return this.api.templates.getAvailableParameters(module, templateId);
  }

  toggleFavoriteTemplate(idTemplate: string): Observable<any> {
    return this.api.templates.toggleFavoriteTemplate(idTemplate);
  }

  deleteTemplate(idTemplate: string): Observable<any> {
    return this.api.templates.deleteTemplate(idTemplate);
  }

  applyHeaderFooter(body: ApplyHeaderFooterBody): Observable<any> {
    return this.api.templates.applyHeaderFooter(body);
  }

  editTemplate(body: AddTemplateBody, moduleType: string): Observable<AddTemplateResponse> {
    return this.api.templates.editTemplate(body, moduleType);
  }

  getSystemEmailTemplate(params: GetSystemTemplateParams): Observable<GetSystemTemplateResponse> {
    return this.api.templates.getSystemEmailTemplate(params);
  }
}
