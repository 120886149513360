import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'em-galleria',
  templateUrl: './galleria.component.html',
  styleUrls: ['./galleria.component.scss']
})
export class GalleriaComponent {
  @Input() mediaType: string;
  @Input() mediaUrl: any;
  @Input() showGalleria: any;
  @Output() closeGalleriaEvent = new EventEmitter<boolean>();

  baseUrl: string = environment.API_URL;
  constructor() {}

  closeGalleryHandler(): void {
    this.closeGalleriaEvent.emit();
  }
}
