import { GetSystemTemplateParams } from '@app/lib/api/templates/api.templates.model';
import { createActionGroup, props } from '@ngrx/store';

export const TemplatesActions = createActionGroup({
  source: 'Templates',
  events: {
    'On Load Templates': props<{
      pageNum: number;
      pageSize: number;
      searchText: string;
      moduleType: string;
      emailType: string;
    }>(),
    'On Load System Templates': props<{
      params: GetSystemTemplateParams;
    }>()
  }
});

export const TemplatesApiActions = createActionGroup({
  source: 'Templates Api',
  events: {
    'On Load System Email Templates Success': props<{ templates: any; totalElement: number }>(),
    'On Load Email Marketing Templates Success': props<{ module: string; templates: any }>(),
    'On Load Header Templates Success': props<{ templates: any }>(),
    'On Load Footer Templates Success': props<{ templates: any }>(),
    'On Load More Header Templates Success': props<{ templates: any }>(),
    'On Load More Footer Templates Success': props<{ templates: any }>(),
    'On Load Templates Failed': props<{ message: string }>()
  }
});
