<div class="template-table">
  <em-smart-table
    [tableData]="tableData"
    [tableHeaders]="headerList"
    [tableName]="tableName"
    [showTableCheckbox]="showTableCheckbox"
    [showViewOption]="true"
    [roundedTable]="true"
    [showTableFilter]="false"
    [paging]="{pageNum, pageSize}"
    [totalElements]="totalElements"
    [checkBoxType]="checkBoxType.star"
    [searchPlaceholder]="'TEMPLATES.TEMPLATE_NAME'"
    [showOnlyGridLayout]="showOnlyGridLayout"
    [isShowConfigHeaderFooter]="isShowConfigHeaderFooter"
    [search]="searchText"
    [isLoading]="(isLoadingTemplate$ | async) || false"
    [isSelectTemplateTable]="isSelectTemplateTable"
    [configTemplate]="configTemplate"
    [(previewHtmlCode)]="previewHtmlCode"
    [isPreviewOnly]="isPreviewOnly"
    [isLargeThumbnail]="isLargeThumbnail"
    (callBackFunction)="dispatchTemplateData(module, emailType)"
    (pagingChange)="onChangePaging($event)"
    (searchItemEvent)="onSearchTemplate($event)"
    (selectItemEvent)="selectItemEvent($event)"
    (onSelectHeaderTemplate)="onSelectHeaderTemplate.emit($event)"
    (onSelectFooterTemplate)="onSelectFooterTemplate.emit($event)"
    (loadMoreHeaderTemplate)="loadMoreHeaderTemplate($event)"
    (loadMoreFooterTemplate)="loadMoreBottomTemplate($event)"
    (editItemEvent)="routeToEditTemplate($event)"
    (deleteItemEvent)="onDeleteTemplate($event)"
    (viewItemEvent)="onPreviewTemplate($event)"></em-smart-table>
  <p-confirmDialog styleClass="max-w-[400px]" />
  <p-dialog
    header="{{ 'TEMPLATES.ERROR_TEMPLATES_HEADER' | translate }}"
    [modal]="true"
    [(visible)]="errorDialogVisible"
    [style]="{ width: '25rem' }"
    [closable]="false">
    <span class="p-text-secondary block mb-5">
      <ng-container [ngSwitch]="emailType">
        <ng-container *ngSwitchCase="templateTypes.HEADER">
          {{ 'TEMPLATES.ERROR_DELETE_HEADER' | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="templateTypes.FOOTER">
          {{ 'TEMPLATES.ERROR_DELETE_FOOTER' | translate }}
        </ng-container>
      </ng-container>
    </span>
    <div class="flex justify-end gap-2">
      <p-button label="Ok" (onClick)="errorDialogVisible = false" />
    </div>
  </p-dialog>
</div>
