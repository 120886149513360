import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { ModeratorRole, ModeratorRoleResponse } from './api.user.model';

export class UserApi {
  private apiUrl: string = environment.API_URL + '/user';

  constructor(
    public readonly http: HttpClient,
    public config: ApiClientConfig
  ) { }

  getModeratorRole(): Observable<ModeratorRole[]> {
    return this.http.get<ModeratorRoleResponse>(`${this.apiUrl}/admin/roles/module`).pipe(
      timeout(this.config.responseTimeout),
      map(res => {
        return res.data;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }
}