import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TemplateState } from './email-template.state';

export const allTemplatesState = createFeatureSelector<TemplateState>('templates');

export const selectSystemTemplates = createSelector(
  allTemplatesState,
  templatesState => templatesState.systemEmailTemplates
);

export const selectMarketingTemplates = createSelector(
  allTemplatesState,
  templatesState => templatesState.emailMarketingTemplates
);

export const selectHeaderTemplates = createSelector(
  allTemplatesState,
  templatesState => templatesState.headerTemplates
);

export const selectFooterTemplates = createSelector(
  allTemplatesState,
  templatesState => templatesState.footerTemplates
);

export const selectLoadingTemplates = createSelector(allTemplatesState, templatesState => templatesState.isLoading);

export const selectTemplateTotalElement = createSelector(
  allTemplatesState,
  templatesState => templatesState.totalElement
);
