import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const dateObj = new Date(value);
    const month = this.padZero(dateObj.getMonth() + 1);
    const day = this.padZero(dateObj.getDate());
    const year = dateObj.getFullYear();
    const hours = this.padZero(dateObj.getHours());
    const minutes = this.padZero(dateObj.getMinutes());
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  }

  padZero(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }
}
