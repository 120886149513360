import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from '@app/core/service/common.service';
import { environment } from '@env/environment';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { AudienceAPI } from '../audience/api.audience.model';

export class AudienceApi {
  SEARCH = '/search';
  AUDIENCES = '/audiences';
  DEFINITION_RULE = '/definition-rules';
  EMAIL_MARKETING = '/email-marketing/';
  RECEIVERS = '/receivers';
  ADVERTISING = '/advertising';
  GROUP_ADS = '/group-ads';
  INTERESTS_BEHAVIOR = '/interests-behavior';
  DELETE_RECEIVERS = '/delete-receivers';
  ADD_RECEIVERS = '/add-receivers';
  ALL_RECEIVERS = '/all-receivers';
  private apiUrl = environment.API_URL;

  constructor(public readonly http: HttpClient, public config: ApiClientConfig, private commonService: CommonService) {}

  getAudienceGroups(
    paramValues: AudienceAPI.GetAudienceGroupsParams | any
  ): Observable<AudienceAPI.AudienceDataResponse> {
    const params = new HttpParams().appendAll(paramValues);
    return this.http
      .get<AudienceAPI.AudienceDataResponse>(
        `${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}${this.AUDIENCES}`,
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAudienceGroupsDetail(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}/${id}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getAudienceGroupsReceivers(paramValues: AudienceAPI.GetDefaultParams | any, id: string): Observable<any> {
    const params = new HttpParams().appendAll(paramValues);
    return this.http
      .get<any>(
        `${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}${this.RECEIVERS}/${id}`,
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getExistingUsers(params: AudienceAPI.GetDefaultParams | any): Observable<any> {
    return this.http.get<any>(`${this.commonService.adminUrl}${this.SEARCH}`, { params }).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getDefinitionRules(module: any): Observable<any> {
    return this.http
      .get(`${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${module?.name}${this.DEFINITION_RULE}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getRegionData(name: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/regional/country/${name}/region-email-marketing`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getDynamicData(url: string): Observable<any> {
    return this.http.get(`${this.apiUrl}${url}`).pipe(
      timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  getInterestsBehavior = (id?: string) =>
    this.http.get(
      `${this.apiUrl}${this.ADVERTISING}${this.GROUP_ADS}${this.INTERESTS_BEHAVIOR}?parentId=${id ? id : ''}`
    );

  createAudienceGroup(data: any): Observable<any> {
    return this.http.post(`${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${data.module_type}`, data).pipe(
      // timeout(this.config.responseTimeout),
      map(response => {
        return response;
      }),
      catchError(error => {
        throw error.error;
      })
    );
  }

  editAudienceGroup(data: any, id: string): Observable<any> {
    return this.http
      .put(`${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}/${id}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteAudienceGroup(id: string): Observable<any> {
    return this.http
      .delete(`${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}/${id}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  addReceivers(id: string, listToAdd: string[]): Observable<any> {
    return this.http
      .put(
        `${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}${this.ADD_RECEIVERS}/${id}`,
        listToAdd
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteReceivers(id: string, listToDelete: string[]): Observable<any> {
    return this.http
      .put(
        `${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}${this.DELETE_RECEIVERS}/${id}`,
        listToDelete
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  updateAudienceGroup(data: AudienceAPI.UpdateAudienceGroupPayload, id: string): Observable<any> {
    return this.http
      .put(`${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}/${id}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getLatestGroupReceivers(id: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.commonService.emailMarketingUrl}${this.AUDIENCES}/${this.commonService.moduleType.name}${this.ALL_RECEIVERS}/${id}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
