<p-dialog
  header="{{ 'TEMPLATES.PREVIEW_EMAIL' | translate }}"
  [(visible)]="showPreviewEmailDialog"
  [modal]="true"
  (onHide)="showPreviewEmailDialogChange.emit(false)"
  styleClass="min-w-[80rem] max-w-[80rem]">
  <div class="content">
    <em-thumbnail-html [html]="html"></em-thumbnail-html>
  </div>
</p-dialog>
