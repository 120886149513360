import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { removeCookie } from 'typescript-cookie';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(environment.TOKEN_KEY);
    if (token) {
      request = request.clone({
        setHeaders: {
          authorization: `Bearer ${(JSON.parse(token) as any).accessToken}`
        }
      });
    }
    return next.handle(request).pipe(
      catchError(err => {
        if ([401].includes(err.status)) {
          sessionStorage.clear();
          localStorage.clear();
          removeCookie(environment.USER_PROFILE);
          removeCookie(environment.TOKEN_KEY);
          this.router.navigateByUrl('/login');
        }

        const error = err.error?.message || err.statusText;
        return throwError(() => error);
      })
    );
  }
}
