export enum AppConnectMessageType {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized',
  SUCCESSFUL = 'success'
}

export enum AppConnectEventType {
  MESSAGE = 'message'
}
