import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'imageUrlFormat'
})
export class ImageUrlFormatPipe implements PipeTransform {
  baseUrl: string = environment.API_URL;
  transform(value: string): string {
    if (!value) return '';

    const hasWebp = value.includes('.webp');
    const isHttpUrl = value.startsWith('http:') || value.startsWith('https:');
    const basePath = '/storage/files/web/';
    if (isHttpUrl && hasWebp) {
      return value;
    }
    const formattedValue = value.includes(basePath) ? value : `${basePath}${value}`;
    return hasWebp ? `${this.baseUrl}${formattedValue}` : `${this.baseUrl}${formattedValue}.webp`;
  }
}
