import { Injectable } from '@angular/core';
import { MARKETING_TOPICS } from '@app/shared/constant';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  moduleType = MARKETING_TOPICS.find(item => item.name === 'SOCIAL') ?? MARKETING_TOPICS[0];
  private apiUrl = environment.API_URL;
  EMAIL_MARKETING = '/email-marketing';
  ADMIN = '/user/admin';
  emailMarketingUrl = `${this.apiUrl}${this.EMAIL_MARKETING}`;
  adminUrl = `${this.apiUrl}${this.ADMIN}`;
}
