import { HttpClient, HttpParams } from '@angular/common/http';
import { CommonService } from '@app/core/service/common.service';
import { catchError, map, Observable, timeout } from 'rxjs';
import { ApiClientConfig } from '../api-client.config';
import { CampaignAPI } from './api.campaign.model';

export class CampaignApi {
  CAMPAIGNS = '/campaigns';
  TOGGLE = '/toggle';
  SENDER_EMAILS = '/sender-emails';
  AUDIENCES = '/audiences';
  DASHBOARD = '/dashboard';
  CAMPAIGN = '/campaign';
  SUMMARY = '/summary';
  DETAIL = '/detail';

  constructor(public readonly http: HttpClient, public config: ApiClientConfig, private commonService: CommonService) {}

  getCampaignData(paramValues: CampaignAPI.GetCampaignDefaultParams | any): Observable<CampaignAPI.CampaignResponse> {
    const params = new HttpParams().appendAll(paramValues);
    return this.http
      .get<CampaignAPI.CampaignResponse>(
        `${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}`,
        { params }
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCampaignDetail(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}/${id}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCampaignSummaryInfo(id: string): Observable<any> {
    return this.http
      .get<any>(`${this.commonService.emailMarketingUrl}${this.DASHBOARD}${this.CAMPAIGN}${this.SUMMARY}/${id}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCampaignEmailSentList(paramValues: { pageNum: number; pageSize: number }, id: string): Observable<any> {
    const params = new HttpParams().appendAll(paramValues);
    return this.http
      .get<any>(`${this.commonService.emailMarketingUrl}${this.DASHBOARD}${this.CAMPAIGN}${this.DETAIL}/${id}`, {
        params
      })
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getCampaignSenderEmails(): Observable<any> {
    return this.http
      .get<any>(
        `${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}${this.SENDER_EMAILS}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  getGroupByCampaignId(id: string): Observable<any> {
    return this.http
      .get<any>(
        `${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}/${id}${this.AUDIENCES}`
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response?.data;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  saveCampaign(data: CampaignAPI.SaveCampaignPayload): Observable<any> {
    return this.http
      .post(`${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  editCampaign(data: CampaignAPI.SaveCampaignPayload, id: string): Observable<any> {
    return this.http
      .put(`${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}/${id}`, data)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  deleteCampaign(id: string): Observable<any> {
    return this.http
      .delete(`${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}/${id}`)
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }

  toggleCampaignStatus(id: string): Observable<any> {
    return this.http
      .put(
        `${this.commonService.emailMarketingUrl}${this.CAMPAIGNS}/${this.commonService.moduleType.name}/${id}${this.TOGGLE}`,
        {}
      )
      .pipe(
        timeout(this.config.responseTimeout),
        map(response => {
          return response;
        }),
        catchError(error => {
          throw error.error;
        })
      );
  }
}
