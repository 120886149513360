<p-galleria
  [value]="mediaUrl"
  [(visible)]="showGalleria"
  containerClass="w-full"
  [numVisible]="1"
  [circular]="false"
  [fullScreen]="true"
  [showItemNavigators]="false"
  [showThumbnails]="false"
  (visibleChange)="closeGalleryHandler()">
  <ng-template pTemplate="item" let-item>
    <span class="max-w-[90vw] max-h-[80vh] rounded-sm">
      <div *ngIf="mediaType === 'VIDEO'; else image">
        <video [src]="mediaUrl" controls autoplay></video>
      </div>
      <ng-template #image>
        <img class="max-w-[90vw] max-h-[80vh] rounded-sm" [src]="mediaUrl" title="Image" />
      </ng-template>
    </span>
  </ng-template>
</p-galleria>
